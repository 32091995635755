import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePropelAuthContext } from 'src/auth/useAuthContext';
import { redirectToKDS } from 'src/auth/utils';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { PATH_DASHBOARD } from '../../routes/paths';

export default function Index() {
  const { pathname, replace, prefetch } = useRouter();
  const { permissions } = usePropelAuthContext();

  useEffect(() => {
    const kdsUrl = redirectToKDS(pathname, permissions);
    const redirectUrl = kdsUrl || PATH_AFTER_LOGIN;
    if (pathname === PATH_DASHBOARD.root) {
      replace(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, permissions]);

  useEffect(() => {
    const kdsUrl = redirectToKDS(pathname, permissions);
    const redirectUrl = kdsUrl || PATH_AFTER_LOGIN;
    prefetch(redirectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  return null;
}
